<template>
  <service-layout type="inspection" :data="form.data" :init="form.init" @confirm="detail.confirm">
    <template #operation>
      <button-ajax
        v-if="form.data.id && form.data.status?.code === 'P' && store.accessRightsHash.VEHICLE_INSPECTION_REPORT_AUDIT"
        type="primary"
        :method="operation.complete.click"
      >
        生成报告
      </button-ajax>
      <button-ajax
        v-if="form.data.id && form.data.status?.code === 'P' && store.accessRightsHash.VEHICLE_INSPECTION_REPORT_EDIT"
        type="primary"
        :method="operation.save.click"
      >
        保存
      </button-ajax>
      <en-button
        v-if="form.data.id && form.data.status?.code === 'P' && store.accessRightsHash.VEHICLE_INSPECTION_REPORT_EDIT"
        type="primary"
        @click="operation.setting.click"
      >
        一键设为良好
      </en-button>
      <en-dropdown v-if="form.data.id" @command="operation.option.command">
        <en-button>操作</en-button>
        <template #dropdown>
          <en-dropdown-item command="detail">检测信息</en-dropdown-item>
          <en-dropdown-item command="customer">客户档案</en-dropdown-item>
          <en-dropdown-item command="vehicle">车辆档案</en-dropdown-item>
          <en-dropdown-item command="delete" :disabled="form.data.status?.code === 'C'">删除</en-dropdown-item>
          <en-dropdown-item command="share" :disabled="form.data.status?.code === 'P'">分享</en-dropdown-item>
        </template>
      </en-dropdown>
    </template>

    <template #manifest>
      <table-manifest
        code="SVCSDBFD"
        :routes="[
          { path: '/service/business/workorder', name: '工单' },
          { path: '/service/business/quotation', name: '估价单' },
          { path: '/service/business/inspection', name: '检测单' }
        ]"
        :ajax="{ action: 'GET /enocloud/common/vehicle/inspection/report' }"
        :props="{ start: 'startInspectDate', end: 'endInspectDate' }"
        @expand-click="dialog.visible = true"
        @row-click="manifest.row.click"
        :ref="setRef('manifest')"
      >
        <template #STATUS="{ row }: { row: EnocloudCommonDefinitions['VehicleInspectionReportDto'] }">
          <div class="flex gap-2 cursor-pointer">
            <en-tag>{{ row.status?.message }}</en-tag>
            <en-tag size="small" :type="row.vehicleServiceGroup?.serviceId ? 'success' : 'info'" @click.stop="manifest.workorder.click(row)">
              工
            </en-tag>
            <en-tag size="small" :type="row.vehicleServiceGroup?.serviceQuotationId ? 'success' : 'info'" @click.stop="manifest.quotation.click(row)">
              估
            </en-tag>
            <en-tag size="small">检</en-tag>
          </div>
        </template>

        <template #VEHICLE_SPEC="{ row }: { row: EnocloudCommonDefinitions['VehicleInspectionReportDto'] }">
          {{ row.vehicle?.vehicleSpec.join(',') }}
        </template>

        <template #form="{ data }">
          <en-form-item label="车主">
            <en-input v-model="data.ownerName"></en-input>
          </en-form-item>
          <en-form-item label="车牌号">
            <en-input v-model="data.plateNo"></en-input>
          </en-form-item>
          <en-form-item label="手机号">
            <en-input v-model="data.cellphone"></en-input>
          </en-form-item>
          <en-form-item label="车架号">
            <en-input v-model="data.vin"></en-input>
          </en-form-item>
          <en-form-item label="单号">
            <en-input v-model="data.reportSerialNo"></en-input>
          </en-form-item>
          <en-form-item label="检测人">
            <en-input v-model="data.inspectorName"></en-input>
          </en-form-item>
        </template>
      </table-manifest>
    </template>

    <en-card v-loading="form.loading" body-class="flex flex-col gap-6">
      <div class="flex gap-4">
        <span>业务选择：检测</span>
        <span>检测方案：{{ form.data.inspectionPlan?.name }}</span>
      </div>

      <en-collapse>
        <en-collapse-item>
          <template #title>
            <div class="flex items-center gap-6">
              <span>{{ form.data.vehicle?.plateNo }}</span>
              <span>{{ form.data.vehicle?.driver }}/{{ form.data.vehicle?.driverCellphone }}</span>
              <span>单号：{{ form.data.serialNo }}</span>
              <en-tag>{{ form.data.status?.message }}</en-tag>
              <span>{{ form.data.vehicle?.vehicleSpec.join('/') }}</span>
              <span>{{ form.data.vehicle?.vin }}</span>
            </div>
          </template>
          <div class="grid grid-cols-4 gap-x-6">
            <span>检测日期： {{ formatDate(form.data.inspectDate) }}</span>
            <span>检测人： {{ form.data.inspector?.name }}</span>
            <span>备注： {{ form.data.comment }}</span>
          </div>
        </en-collapse-item>
      </en-collapse>
    </en-card>

    <en-card v-loading="form.loading" class="flex-1 overflow-auto" body-class="h-full">
      <flex-box>
        <template #default="{ height }">
          <en-tabs v-model="tabs.active">
            <en-tab-pane label="项目" name="maintenance" :badge="form.reportItemsData?.length">
              <en-table :data="form.reportItemsData" :height="height - 55" :span-method="form.reportItems.spanMethod">
                <en-table-column label="检测分类" prop="categoryName"></en-table-column>
                <en-table-column label="检测项目" prop="inspectionItem.name"></en-table-column>
                <en-table-column label="检测结果" width="300">
                  <template #default="{ row }: { row: EnocloudCommonDefinitions['VehicleInspectionReportItemDto'] }">
                    <en-button
                      v-for="(item, index) in [
                        row.inspectionItem?.excellentConditionDescription,
                        row.inspectionItem?.fairConditionDescription,
                        row.inspectionItem?.poorConditionDescription
                      ]"
                      size="small"
                      :type="form.evaluation.type(row, index)"
                      @click="form.evaluation.click(row, index)"
                      :disabled="form.data.status?.code === 'C'"
                    >
                      {{ item }}
                    </en-button>
                  </template>
                </en-table-column>
                <en-table-column label="检测图片" prop="imageUrls">
                  <template #default="{ row }: { row: EnocloudCommonDefinitions['VehicleInspectionReportItemDto'] }">
                    <en-button type="primary" @click="form.imgUrls.click(row)">检测图片</en-button>
                  </template>
                </en-table-column>
                <en-table-column label="检测备注" prop="comment">
                  <template #default="{ row }: { row: EnocloudCommonDefinitions['VehicleInspectionReportItemDto'] }">
                    <en-input v-model="row.comment" :disabled="form.data.status?.code === 'C'"></en-input>
                  </template>
                </en-table-column>
                <en-table-column label="检测说明" prop="description"></en-table-column>
              </en-table>
            </en-tab-pane>
          </en-tabs>
        </template>
      </flex-box>
    </en-card>
  </service-layout>

  <inspection-dialog v-model="dialog.visible"></inspection-dialog>

  <inspection-detail v-model="detail.visible" :data="form.data" @confirm="detail.confirm"></inspection-detail>

  <customer-record
    v-model="customerRecord.visible"
    :customer-data="form.data.vehicle?.owner"
    :vehicle-data="form.data.vehicle"
    @confirm="form.get"
  ></customer-record>

  <vehicle-record v-model="vehicleRecord.visible" :data="form.data.vehicle" @confirm="form.get"></vehicle-record>

  <en-drawer v-model="imgUrls.visible" title="检测照片">
    <upload-maintain
      v-if="imgUrls.data"
      v-model="imgUrls.data.imageUrls"
      multiple
      dir="report"
      :disabled="imgUrls.disabled"
      :limit="2"
    ></upload-maintain>

    <template #footer>
      <en-button @click="imgUrls.footer.cancel.click">取消</en-button>
      <en-button type="primary" :disabled="imgUrls.disabled" @click="imgUrls.footer.confirm.click">确定</en-button>
    </template>
  </en-drawer>
</template>

<script lang="ts">
import { h } from 'vue'
import { CustomerRecord, VehicleRecord } from '@enocloud/business'
import { EnMessageBox } from '@enocloud/components'

import ServiceLayout from '@service/components/service-layout.vue'

import InspectionDetail from '@service/components/inspection-detail.vue'
import InspectionDialog from '@service/components/inspection-dialog.vue'

import type { TableSpanMethodData } from '@enocloud/components'

export default factory({
  components: { ServiceLayout, InspectionDetail, InspectionDialog, CustomerRecord, VehicleRecord },

  config: {
    children: {
      operation: {
        save: {
          async click() {
            await this.form.submit()
            return this.form.get()
          }
        },
        complete: {
          async click() {
            await this.dirtyCheck('form')
            await this.form.submit()
            await this.form.complete()
            return this.form.get()
          }
        },
        setting: {
          click() {
            for (const item of this.form.data?.reportItems ?? []) {
              item.evaluation = { code: 'E', message: '', description: '', type: '' }
              item.comment = '状态良好'
            }
          }
        },
        option: {
          async command(option: string) {
            await this.dirtyCheck('form')
            switch (option) {
              case 'detail':
                this.detail.visible = true
                break
              case 'customer':
                this.customerRecord.visible = true
                break
              case 'vehicle':
                this.vehicleRecord.visible = true
                break
              case 'delete':
                await this.form.delete()
                this.form.init()
                this.refs.manifest.table.method()
                break
              case 'share':
                const res = await this.form.share()
                if (res.data[0]) {
                  EnMessageBox({
                    title: `${this.form.data.vehicle?.plateNo}的车况报告`,
                    center: true,
                    showConfirmButton: false,
                    message: h('img', { src: res.data[0], width: 300, height: 300, class: 'm-auto' })
                  })
                }
                break
            }
          }
        }
      },
      manifest: {
        row: {
          click(row: EnocloudCommonDefinitions['VehicleInspectionReportDto']) {
            this.form.init()
            this.form.data.id = row.id
            this.form.get()
          }
        },
        workorder: {
          click(row: EnocloudCommonDefinitions['VehicleInspectionReportDto']) {
            if (!row.vehicleServiceGroup?.serviceId) return
            this.router.push('/service/business/workorder', (vm) => {
              vm.form.data.id = row.vehicleServiceGroup?.serviceId
              vm.form.get()
            })
          }
        },
        quotation: {
          click(row: EnocloudCommonDefinitions['VehicleInspectionReportDto']) {
            if (!row.vehicleServiceGroup?.serviceQuotationId) return
            this.router.push('/service/business/quotation', (vm) => {
              vm.form.data.id = row.vehicleServiceGroup?.serviceQuotationId
              vm.form.get()
            })
          }
        }
      },
      tabs: {
        active: 'maintenance'
      },
      form: {
        ajax: {
          get: {
            action: 'GET /enocloud/common/vehicle/inspection/report/:reportId',
            data: 'object',
            init: true,
            loading: true,
            dirty: true,
            params(params) {
              params.paths = [this.form.data.id]
            }
          },
          submit: {
            action: 'PUT /enocloud/common/vehicle/inspection/report',
            loading: true,
            params(params) {
              params.payload = this.form.data
            }
          },
          complete: {
            action: 'PUT /enocloud/common/vehicle/inspection/report/:reportId/complete',
            params(params) {
              params.paths = [this.form.data.id]
            }
          },
          share: {
            action: 'GET /enocloud/common/vehicle/inspection/report/:reportId/share',
            params(params) {
              params.paths = [this.form.data.id]
            }
          },
          delete: {
            action: 'DELETE /enocloud/common/vehicle/inspection/report/:reportId',
            params(params) {
              params.paths = [this.form.data.id]
            }
          }
        },
        rules: {},
        disabled: false,
        computed: {
          reportItemsData() {
            return this.form.data.reportItems?.sort((a, b) => a.inspectionItem?.category?.id! - b.inspectionItem?.category?.id!)
          },
          reportItemsSpans() {
            const hash = new Map()
            return this.form.reportItemsData?.map((item) => {
              const res = { rowspan: 0, colspan: 1 }
              const exist = hash.get(item.inspectionItem?.category?.id!)

              if (!exist) hash.set(item.inspectionItem?.category?.id!, true)

              res.rowspan = exist
                ? 0
                : this.form.reportItemsData?.filter((r) => r.inspectionItem?.category?.id === item?.inspectionItem?.category?.id).length ?? 1
              return res
            })
          }
        },
        children: {
          reportItems: {
            spanMethod({ rowIndex, columnIndex }: TableSpanMethodData) {
              if (columnIndex === 0) {
                return this.form.reportItemsSpans?.[rowIndex]
              }
            }
          },
          evaluation: {
            type(row: EnocloudCommonDefinitions['VehicleInspectionReportItemDto'], index: number) {
              let code = row.evaluation?.code
              switch (code) {
                case 'E':
                  return index === 0 ? 'success' : ''
                case 'F':
                  return index === 1 ? 'warning' : ''
                case 'P':
                  return index === 2 ? 'danger' : ''
              }
            },
            click(row: EnocloudCommonDefinitions['VehicleInspectionReportItemDto'], index: number) {
              let code: string = ''
              switch (index + 1) {
                case 1:
                  code = 'E'
                  row.comment = '状态良好'
                  break
                case 2:
                  code = 'F'
                  row.comment = '密切关注'
                  break
                case 3:
                  code = 'P'
                  row.comment = '急需处理'
                  break
              }
              row.evaluation = { code, message: '', description: '', type: '' }
            }
          },
          imgUrls: {
            click(row: EnocloudCommonDefinitions['VehicleInspectionReportItemDto']) {
              this.imgUrls.data = { ...row }
              this.imgUrls.disabled = Boolean(this.form.data.status?.code === 'C')
              this.imgUrls.visible = true
            }
          }
        }
      },
      dialog: {
        visible: false
      },
      detail: {
        visible: false,
        confirm(id: number | undefined) {
          this.form.data.id ??= id
          this.form.get()
        }
      },
      imgUrls: {
        visible: false,
        disabled: false,
        data: null as EnocloudCommonDefinitions['VehicleInspectionReportItemDto'] | null,
        children: {
          footer: {
            cancel: {
              click() {
                this.imgUrls.visible = false
              }
            },
            confirm: {
              click() {
                const row = this.form.data.reportItems?.find((item) => item.id === this.imgUrls.data?.id)
                if (row) Object.assign(row, this.imgUrls.data)
                this.imgUrls.footer.cancel.click()
              }
            }
          }
        }
      },
      vehicleRecord: {
        visible: false
      },
      customerRecord: {
        visible: false
      }
    }
  }
})
</script>
